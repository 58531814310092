import React, { useState, useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import { useParams } from "react-router-dom";
import Footer from "../global-components/footer";
import ServiceBlock from "./ServicesBlock";
import Values from "../section-components/values";
import Map  from "../contact-component/map";

export const ServicesDetail = () => {
  const { rpdata } = useContext(GlobalDataContext);
  const { id } = useParams();
  console.log(id);

  return (
    <div>
      <Navbar />
      {rpdata?.dbServices?.map((item) => {
        if (item.name.replace(" ", "-").toLowerCase() === id) {
          return (
            <div>
              <PageHeader
                headertitle={item.name}
                Subheader={item.name}
                backgroundImage={item.description[0].img}
              />
              <ServiceBlock
                servicesName={item.name}
                imgServices={item.description[0].img}
                description={item.description.map((i, index) => {
                  return <p>{i.text}</p>;
                })}
                itemServives={item.description.map((it, index) => {
                  return it.lists[0] !== " "
                    ? it.lists.map((list, index) => {
                        return (
                          <li
                            className="single-list-inner style-check-box"
                            key={index}
                          >
                            <i className="fa fa-check text-white" /> {list}
                          </li>
                        );
                      })
                    : null;
                })}
              />
            </div>
          );
        }
      })}
      <Values/>
      <Map ></Map>
      <Footer />
    </div>
  );
};

import React,{useContext} from 'react';
import { GlobalDataContext } from '../../context/context';

function VideoPromo() {
    const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div
        className=" bg-overlay video_promo"
        style={{ backgroundImage: `url("${rpdata?.stock?.[0]})"` }}
      >
        <div className="container">
          <div className="breadcrumb-inner">
            <div className="section-title mb-0 text-center">
            <iframe src="https://www.youtube.com/embed/xinxgupORtk?autoplay=1" className='video_prm' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoPromo;

import React, { useContext } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import About from "./section-components/about-v2";
import Values from "./section-components/values";
import Footer from "./global-components/footer";
import BlockAboutTwo from "./about-components/block-about-two";
import { GlobalDataContext } from "../context/context";

const AboutPage = () => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle="About"
        Subheader="About"
        backgroundImage={rpdata?.gallery?.[8]}
      />
      <About sectionclass="pd-bottom-50" />
      <Values />
      <BlockAboutTwo />
      <Footer />
    </div>
  );
};

export default AboutPage;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";

const AboutPage = (props) =>  {
	const { rpdata } = useContext(GlobalDataContext);
  let publicUrl = process.env.PUBLIC_URL + "/";


  return (
    <div className={"about-area pd-top-120 go-top "}>
      <div className="container">
        <div className="about-area-inner">
          <div className="row">
            <div className="col-lg-6 col-md-10">
              <div
                className="about-thumb-wrap after-shape"
                style={{ backgroundImage:`url("${rpdata?.gallery?.[6]}")`}}
              ></div>
            </div>
            <div className="col-lg-6">
              <div className="about-inner-wrap">
                <div className="section-title mb-0">
                  <h6 className="sub-title right-line">
				  {rpdata?.dbPrincipal?.name}
                  </h6>
                  <h2 className="title">{rpdata?.dbSlogan?.[6].slogan}</h2>
                  <p className="content">{rpdata?.dbHome?.[0].text}</p>
                  <div className="row">
                    <div className="col-sm-6">
                      <ul className="single-list-wrap">
                        { rpdata?.dbServices?.slice(0,6).map((item, index) => {
                          return (
                            <li
                              className="single-list-inner style-check-box"
                              key={index}
                            >
                              <i className="fa fa-check text-white" /> {item.name}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <Link className="btn btn-border-black " to="/contact">
                    Free Estimate
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
